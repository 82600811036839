<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="error error-6 d-flex flex-row-fluid bgi-size-cover bgi-position-center"
      :style="{
        backgroundImage: `url(${backgroundImage})`,
      }"
    >
      <!-- begin:: Content -->
      <div class="d-flex flex-column flex-row-fluid text-center mx-8">
        <h1 class="error-title font-weight-boldest text-white mb-10" style="margin-top: 12rem">
          {{ $t("ERROR.500_TITLE") }}
        </h1>
        <p class="display-4 font-weight-bold text-white">
          {{ $t("ERROR.500_SUBTITLE") }}
        </p>
        <div class="row">
          <div class="col-3"></div>
          <div class="col-6 col-xs-12">
            <p class="text-white mt-6 text-bold">
              {{ $t("ERROR.500_MESSAGE") }}
            </p>
          </div>
          <div class="col-3"></div>
        </div>
        <router-link class="btn btn-borderless font-weight-bolder font-size-h4 float-right text-white" :to="{ name: 'dashboard' }">
          {{ $t("GENERAL.HOME") }}
        </router-link>
      </div>
      <!-- end:: Content -->
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/pages/error/error-6.scss";
</style>

<script>
export default {
  name: "Error-500",
  mounted() {},
  computed: {
    backgroundImage() {
      return this.$laUtils.backgroundImage("media/error/bg6.jpg");
    },
  },
};
</script>
